<template>
  <div class="support-section">
    <LetsChat></LetsChat>
    <div id="hero-support">
      <div class="menu-wrapper">
        <img class="logoBar" :src="logoBar" alt="Logo Scriptics">
      </div>
      <div class="border-console">
        <span class="console-title">Scriptics DevOps</span>
        <div id="demo"></div>
      </div>
      <div class="border-hero">
        <p class='real-time'></p>
        <p class="console-like-text">[root@Scriptics devOPs]</p>
        <span>
                    <img src="../assets/support/s-icon-linux.svg">
                </span>
      </div>
    </div>

    <div class="we-support">
      <div>
        <h2>We support</h2>
        <p>Partnering with the Scriptics team means more time to focus on serving your customers and growing your business. The Scriptics Observer platform monitors clients’ systems 24/7 and we have a dedicated on-call team ready to resolve urgent issues immediately. Working with us, you don’t have to accept "next business day" delays when it matters most.
        </p>
      </div>
    </div>

    <div id="scr-custom-map-support">
      <div class="scr-custom-map-support"></div>
    </div>
    <ContactUs></ContactUs>
    <Footer color="#181818"></Footer>
  </div>
</template>

<script>
import $ from 'jquery'
import Footer from "../components/Home/Footer";
import logoScr from '@/assets/scriptics-logo-menu-mob.svg';
import ContactUs from "../components/ContactUs";
import LetsChat from "../components/LetsChat";


export default {
  name: "Support",
  components: {LetsChat, ContactUs, Footer},

  data: function () {
    return {
      logoBar: logoScr,
    }
  },
  mounted() {
    var dt = new Date();
    var str = '<ul>' +
            '<li>Booting</li>' + '<li>...........</li>' + '<span>                   </span>' + '<li> Welcome to Scriptics  </li>' +
            '<li>Impresing? :) </li>' + '<li>We are pleased to have you on our cool DevOps page</li>' + '<li>Here are some commands you can use: </li>' +
            '<li>Commands:</li>' +
            '<p>help<br></p>' +
            '<p>team</p>' +
            '<p>pug</p>' +
            '<p>support</p>' +
            '<p>contact</p>' +
            '<p>what-we-do</p>' +
            '<p>maximize</p>' +
            '<p>facebook</p>' +
            '<p>location</p>'
        ,
        time = dt.getHours() + ":" + dt.getMinutes() + " ",
        i = 0,
        isTag,
        text;

    (function type() {
      text = str.slice(0, ++i);

      if (text === str) return;

      document.getElementById('demo').innerHTML = text;

      var char = text.slice(-1);
      if (char === '<') isTag = true;
      if (char === '>') isTag = false;
      var listItems = document.getElementById('demo').getElementsByTagName("li");

      $(listItems).before('<span class="clearfix"></span><span style="color: green">' + time + ' ' + '~scriptics~ ' + '</span>');
      if (isTag) return type();


      setTimeout(type, 25);
    }());

    var tymevr = str.length * 22;

    function second() {
      $('#demo').append('<br><li class="first-element"><span style="color: green">guest@scriptics:~#</span>' + "<input class='name' type='text' autofocus></li>");
    }

    $(document).keypress(function (e) {
      var key = e.which;
      var thisvalue = $(".name").val();
      if (key == 13)  // the enter key code
      {


        if (thisvalue.toLowerCase() === "pug") {
          window.open('https://scriptics.ro/team.php#parallax_15_content', '_blank');
          $('input.name').val("");
          var elem = document.getElementById('Puggy Sue');
          elem.scrollTop = elem.scrollHeight;
        } else if (thisvalue.toLowerCase() === "team") {
          window.open('https://scriptics.ro/team.php', '_blank');
          $('input.name').val("");
          var elem = document.getElementById('demo');
          elem.scrollTop = elem.scrollHeight;
        } else if (thisvalue.toLowerCase() === "location") {
          window.open('https://www.google.ro/maps/place/Scriptics+Decisions/@45.791665,24.1398013,17z/data=!4m12!1m6!3m5!1s0x474c67bd7ae4347f:0x96a04eca93c04caa!2sScriptics+Decisions!8m2!3d45.791665!4d24.14199!3m4!1s0x474c67bd7ae4347f:0x96a04eca93c04caa!8m2!3d45.791665!4d24.14199', '_blank');
          $('input.name').val("");
          var elem = document.getElementById('demo');
          elem.scrollTop = elem.scrollHeight;
        } else if (thisvalue.toLowerCase() === "facebook") {
          window.open('https://www.facebook.com/Scriptics/', '_blank');
          $('input.name').val("");
          var elem = document.getElementById('demo');
          elem.scrollTop = elem.scrollHeight;
        } else if (thisvalue.toLowerCase() === "maximize") {
          $('.border-console').css('width', '100%');
          $('input.name').val("");
          var elem = document.getElementById('demo');
          elem.scrollTop = elem.scrollHeight;
        } else if (thisvalue.toLowerCase() === "minimize") {
          $('.border-console').css('width', '60%');
          $('input.name').val("");
          var elem = document.getElementById('demo');
          elem.scrollTop = elem.scrollHeight;
        } else if (thisvalue.toLowerCase() === "contact") {
          window.open('https://scriptics.ro/form/scriptics.php', '_blank');
          $('input.name').val("");
          var elem = document.getElementById('demo');
          elem.scrollTop = elem.scrollHeight;
        } else if (thisvalue.toLowerCase() === "support") {
          window.open('https://support.scriptics.ro', '_blank');
          $('input.name').val("");
          var elem = document.getElementById('demo');
          elem.scrollTop = elem.scrollHeight;
        } else if (thisvalue.toLowerCase() === "help") {
          if (!$('.error').length) {
            $('.first-element').replaceWith("<li class='error'><p > Commands: </p><p >pug</p><p > meet_team</p><p > support_portal</p><p > what_you_can_do</p> <p> contact</p><p>maximize</p><p>minimize</p><p >help</p><span style='color: green'>guest@scriptics:~# </span><input class='name' type='text' autofocus></li><br>");
          } else {
            $('.error').replaceWith("<li class='error'><p > Commands: </p><p >pug</p><p > meet_team</p><p > support_portal</p><p > what_you_can_do</p> <p> contact</p><p>maximize</p><p>minimize</p><p >help</p><p>                  </p><span style='color: green'>guest@scriptics:~# </span><input class='name' type='text' autofocus></li><br>");

            $('.error').children('input').val("");
            $('input.name').val("");
          }

          $('input').focus();
          var elem = document.getElementById('demo');
          elem.scrollTop = elem.scrollHeight;
        } else if (thisvalue.toLowerCase() === "what-we-do") {
          if (!$('.error').length) {
            $('.first-element').replaceWith("<li class='error' style='padding-left:0; padding-top: 10px'><p style='color: green'>What we do?<p > We're fluent in all the linux distros and we can do almost anything from setting up your email sever to building you REST API's or setting your enviroment with continous integration & continous deployment & we can monitor your systems 24/7 via our Scriptics Observer (based on Nagios) </p><p > Here are a few of the things we are acoustomed with:</p><p > Proxmox</p><p > Nagios</p><p > Docker</p> <p> DAmazon WebService</p><p > GitLab</p><p > BitBucket</p><p > API's</p><p > Crawlers</p><p > Reporting Engines</p><p > Managed Services</p><p > Ticketing Systems</p><span style='color: green'>guest@scriptics:~# </span><input class='name' type='text' autofocus></li><br>");
          } else {
            $('.error').replaceWith("<li class='error' style='padding-left:0; padding-top: 10px'><p style='color: green'>What we do?<p > We're fluent in all the linux distros and we can do almost anything from setting up your email sever to building you REST API's or setting your enviroment with continous integration & continous deployment & we can monitor your systems 24/7 via our Scriptics Observer (based on Nagios) </p><p > Here are a few of the things we are acoustomed with:</p><p > Proxmox</p><p > Nagios</p><p > Docker</p> <p> DAmazon WebService</p><p > GitLab</p><p > BitBucket</p><p > API's</p><p > Crawlers</p><p > Reporting Engines</p><p > Managed Services</p><p > Ticketing Systems</p><span style='color: green'>guest@scriptics:~# </span><input class='name' type='text' autofocus></li><br>");

            $('.error').children('input').val("");
            $('input.name').val("");
          }

          $('input').focus();
          var elem = document.getElementById('demo');
          elem.scrollTop = elem.scrollHeight;
        } else {
          if (!$('.error').length) {
            $('.first-element').replaceWith('<li class="error"><span style="color:red ; display: block;">' + thisvalue + ' command doesn\'t exist' + "</span><label style='color: green'>guest@scriptics:~# </label><input class='name' type='text' autofocus></li><br>");
          } else {
            $('input.name').before('<li class="error"><label style="color: green">guest@scriptics:~# </label><span style="color:red ;display: block;">' + thisvalue + ' command doesn\'t exist' + "</span></li><br>");

            $('.error').children('input').val("");
          }
          $('input').focus();
          var elem = document.getElementById('demo');
          elem.scrollTop = elem.scrollHeight;
        }
      }
    });

    setTimeout(second, tymevr);

  },
}


</script>

<style>
.support-section {
  display: block;
  transition: background 2s, ease-in-out;
}
#hero-support {
  background-color: #3d5271;
  height: 87vh;
  position: relative;
}

.menu-wrapper {
  background-color: #252525;
  height: 70px;
}

.logoBar {
  display: inline-block;
  position: absolute;
  margin-top: 15px;
  width: 40px;
  text-align: center;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

ul li a {
  text-decoration: none
}

#demo {
  min-height: 25em;
  max-height: 25em;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 1);
  overflow-y: scroll;
  overflow-x: hidden;
  font-family: sans-serif;
  font-weight: 600;
  font-size: 15px;
  padding: 10px;
  -webkit-box-shadow: 6px 6px 5px 0 rgba(0, 0, 0, .75);
  -moz-box-shadow: 6px 6px 5px 0 rgba(0, 0, 0, .75);
  box-shadow: 6px 6px 5px 0 rgba(0, 0, 0, .75);
}

.border-hero {
  background-color: #2a2a2a;
  height: 70px;
  position: absolute;
  bottom: 0;
  width: 100%
}

.border-console {
  padding-top: 0;
  height: 400px;
  margin: 2% auto;
  width: 60%;
  background: #f34840;
  border-radius: 10px;
  border: 2px solid #555;
}

.console-title {
  padding: 10px;
  text-align: center;
  margin: 0 auto;
  display: block;
  color: #fafafa;
}

#demo input {
  background-color: transparent;
  border: none;
  outline: 0;
  animation: 1s animateBorder infinite;
  padding: 2px;
  width: 165px;
  color: transparent;
  text-shadow: 0 0 0 #fff;
  -webkit-text-fill-color: transparent
}

@keyframes animateBorder {
  from {
    border-left: 5px solid rgba(255, 255, 255, .8)
  }
  to {
    border-left: 5px solid rgba(255, 255, 255, .2)
  }
}

.clearfix {
  display: table
}

#demo li {
  display: inline;
}

#demo p {
  display: block;
  width: 100%;
  padding-left: 30px;
}


#demo input,
#demo li,
#demo p,
#demo span {
  font-family: monospace;
  font-size: 15px;
  font-weight: 600;
  color: #fafafa;
}

.we-support {
  color: #000;
  text-align: center;
  padding: 8%;
  display: inline-block;
  background-color: #fafafa;
}

.we-support h2 {
  color: #f34840;
  font-size: 32px;
  font-weight: 200;
}

.we-support h3 {
  color: #f34840;
  font-size: 30px;
  padding: 30px;
}

.we-support p {
  color: #000;
  padding: 30px 30px 65px;
  font-weight: 400;
  line-height: 24px;
  font-size: 20px;
  width: 100%;
}

.border-hero img {
  float: right;
  position: absolute;
  right: 15px;
  top: 15px;
}

.console-like-text {
  text-align: center;
  float: none;
  display: block;
  margin-top: 18px;
  color: green;
  font-size: 24px;
  font-weight: 200;
}

.we-support {
  color: #f34840;
}

.scr-custom-map-support {
  position: relative;
  display: block;
  height: 100%;
  margin: 0 auto;
  background: url(../assets/support/map-support.svg) no-repeat;
  background-size: contain;
  background-position: center;
}

#scr-custom-map-support {
  height: 100vh;
  background-color: #191919;
  border: none;
}


@media only screen and (max-width: 768px) {
  .border-console {
    width: 90%;
  }

  .we-support {
    color: #000;
    min-height: fit-content;
    text-align: center;
    padding: 5%;
  }

  .console-like-text {
    font-size: 16px;
    margin-top: 24px;
  }
}

@media only screen and (max-width: 1024px) {
  #scr-custom-map-support {
    height: 73vh;
  }
}
@media only screen and (max-width: 768px) {
  #scr-custom-map-support {
    height: 55vh;
  }
}
@media only screen and (max-width: 500px) {
  #scr-custom-map-support {
    height: 34vh;
  }
}

</style>
